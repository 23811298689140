<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              영업일자
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="getFeeDivisionInfo"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">영업일보(요금구분별)</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :is-shortcut-button="true"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="dailyReportFeeDivision"
                v-bind="gridSetting"
                :data-source="dataSource"
                :allowExcelExport="true"
                :allowGrouping="true"
                @queryCellInfo="onQueryCellInfo"
            />
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import moment from 'moment';
import {DATE_FORMAT_YYYY_MM_DD,} from '@/utils/date';
import {Aggregate, ExcelExport,Group} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import ErpButton from "@/components/button/ErpButton.vue";
import ReportView from '@/components/common/report/ReportView';
import {mapGetters} from "vuex";
import { numberWithCommas } from '@/utils/number';
import GolfErpAPI from '@/api/v2/GolfErpAPI';

export default {
  name: 'DailyReportFeeDivision',
  components: {
    ejsGridWrapper,
    InputDateRange,
    ErpButton,
    ReportView
  },
  mixins: [confirmDialogMixin],
  mounted() {
    this.init();
  },
  computed:{
    ...mapGetters(['username']),
  },
  data() {
    return {
      dateRange: {from:null , to:null },
      dataSource: [],
      pay: {},
      rowSpanObject: {},
      gridSetting: {
        isNOColumnDisplay: false,
        columns: [
          { field: 'group', type: 'string', visible: false,},
          { field: 'divName', headerText: '구분', textAlign: 'center', width: 80, type: 'string',},
          { field: 'paymtCodeName', headerText: '적요', textAlign: 'center', width: 100, type: 'string'},
          {
            headerText: '실매출액',
            columns:[
              { field: 'visit18HPer', headerText: '18h', textAlign: 'center', width: 80, type: 'string'},
              { field: 'visit9HPer', headerText: '9h', textAlign: 'center', width: 70, type: 'string'},
              { field: 'netAmt', headerText: '과세', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'notaxAmt', headerText: '비과세', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'totalSales', headerText: '계', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
            ]
          },
          {
            headerText: '제세공과금',
            columns:[
              { field: 'specialTax', headerText: '개별소비세', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'vilTax', headerText: '농특세', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'eduTax', headerText: '교육세', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'vatAmt', headerText: '부가세', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'fund', headerText: '체육기금', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
              { field: 'totalDues', headerText: '계', textAlign: 'right', width: 110, isNumericType: true, type: 'number',},
            ]
          },
          { field: 'totAmt', headerText: '합계', textAlign: 'right', width: 140, isNumericType: true, type: 'number',},
        ],
        provides: [Aggregate, Group, ExcelExport,],
        groupSettings: { columns: ["group"], showDropArea: false,},
        aggregates: [
          { //소계
            columns: [
              { field: "paymtCodeName", aggregationType: "GroupCaption", customAggregate: (data) => {
                return data.items[0].divName + ' 소계';
                },},
              { field: "visit18HPer", aggregationType: "GroupSum",},
              { field: "visit9HPer", aggregationType: "GroupSum",},
              { field: "netAmt", aggregationType: "GroupSum",},
              { field: "notaxAmt", aggregationType: "GroupSum",},
              { field: "totalSales", aggregationType: "GroupSum",},
              { field: "specialTax", aggregationType: "GroupSum",},
              { field: "vilTax", aggregationType: "GroupSum",},
              { field: "eduTax", aggregationType: "GroupSum",},
              { field: "vatAmt", aggregationType: "GroupSum",},
              { field: "fund", aggregationType: "GroupSum",},
              { field: "totalDues", aggregationType: "GroupSum",},
              { field: "totAmt", aggregationType: "GroupSum",},
            ]
          },
          { //Total 매출
            columns: [
              { field: "divName", aggregationType: "TotalCaption", customAggregate: "합계",},
              { field: "paymtCodeName", aggregationType: "TotalCaption", customAggregate: "(매출)",},
              { field: "visit18HPer", aggregationType: "TotalSum",},
              { field: "visit9HPer", aggregationType: "TotalSum",},
              { field: "netAmt", aggregationType: "TotalSum",},
              { field: "notaxAmt", aggregationType: "TotalSum",},
              { field: "totalSales", aggregationType: "TotalSum",},
              { field: "specialTax", aggregationType: "TotalSum",},
              { field: "vilTax", aggregationType: "TotalSum",},
              { field: "eduTax", aggregationType: "TotalSum",},
              { field: "vatAmt", aggregationType: "TotalSum",},
              { field: "fund", aggregationType: "TotalSum",},
              { field: "totalDues", aggregationType: "TotalSum",},
              { field: "totAmt", aggregationType: "TotalSum",},
            ]
          },
          { //Total 정산
            columns: [
              { field: "divName", aggregationType: "TotalCaption", customAggregate: "합계",},
              { field: "paymtCodeName", aggregationType: "TotalCaption", customAggregate: "(정산)",},
              { field: "netAmt", aggregationType: "TotalCaption", customAggregate: () => { return '현금 ' + this.payDefaultSetting(this.pay.payCash); }},
              { field: "notaxAmt", aggregationType: "TotalCaption", customAggregate: () => { return '카드 ' + this.payDefaultSetting(this.pay.payCard); }},
              { field: "totalSales", aggregationType: "TotalCaption", customAggregate: () => { return '외상 ' + this.payDefaultSetting(this.pay.payCredit); }},
              { field: "specialTax", aggregationType: "TotalCaption", customAggregate: () => { return '선수금 ' + this.payDefaultSetting(this.pay.payDeposit); }},
              { field: "vilTax", aggregationType: "TotalCaption", customAggregate: () => { return '상품권 ' + this.payDefaultSetting(this.pay.payGift); }},
              { field: "eduTax", aggregationType: "TotalCaption", customAggregate: () => { return '마일리지 ' + this.payDefaultSetting(this.pay.payPoint); }},
              { field: "totAmt", aggregationType: "TotalCaption", customAggregate: () => { return '총액 ' + this.payDefaultSetting(this.pay.payTotAmt); }},
            ]
          }
        ]
      }
    };
  },
  methods: {
    excel() {
      this.$refs.dailyReportFeeDivision.excelExport();
    },
    print() {
      const searchOptionsList = [
        {
          key: '영업일자',
          value:
              this.dateRange.from && this.dateRange.to
                  ? this.dateRange.from + ' ~ ' + this.dateRange.to
                  : null,
        },
      ];

      const searchOptions = searchOptionsList
          .filter(item => !!item.value)
          .map(item => item.key + ': ' + item.value)
          .join(', ');
      const reportData = {
        reportJson: {
          jsonData: {
            pay: this.pay,
            data: this.dataSource
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    init() {
      const now = moment();
      this.dateRange = {
        from: now.format(DATE_FORMAT_YYYY_MM_DD),
        to: now.format(DATE_FORMAT_YYYY_MM_DD)
      };
      this.getFeeDivisionInfo();
    },
    async getFeeDivisionInfo() {
      const {from,to} = this.dateRange;
      const {pay,sales} = await GolfErpAPI.getReportFeeDivision(from,to);
      this.pay = pay;
      this.settingDataSource(sales);
      this.rowSpanSetting(sales,'div');
    },
    rowSpanSetting(array,div) {
      // 배열과 구분값을 넣으면 알아서 구분값으로 그룹화를 하고 합쳐진 갯수를 오브젝트형태로 만들어줌
      // group 을 사용하고 queryCellInfo 에서 rowSpan 을 사용하면 error 발생
      // 다른곳에서 공통으로 사용할 함수 예정
      const rowSpanObject =  {};
      for(const object of array) {
        if(!rowSpanObject.hasOwnProperty.call(rowSpanObject,object[div])) {
          rowSpanObject[object[div]] = 0;
        } else {
          rowSpanObject[object[div]]++;
        }
      }
      this.rowSpanObject = rowSpanObject;
    },
    settingDataSource(sales) {
      let beforeData;
      this.dataSource = sales
        .filter(item =>
          ![
            sales.filter(item => item.paymtCode === "TEE3").map(item => item.totAmt).reduce((acc, cur) => acc + cur, 0) > 0 ? null : "TEE3",
            sales.filter(item => item.paymtCode === "TEE4").map(item => item.totAmt).reduce((acc, cur) => acc + cur, 0) > 0 ? null : "TEE4",
            sales.filter(item => item.paymtCode === "TEE5").map(item => item.totAmt).reduce((acc, cur) => acc + cur, 0) > 0 ? null : "TEE5"
          ].includes(item.paymtCode)
        ).map((item) => {
          const returnData =  {
            ...item,
            group: item.sortNo + item.div, // 그룹화시 정렬하기 위해 sortNo 추가
            isGroupFirstIdx: beforeData !== item.div,
            totalSales: this.itemReduce(item,'sales'),
            totalDues: this.itemReduce(item,'dues')
          };
          beforeData = returnData.div;
          return returnData;
        });
    },
    payDefaultSetting(pay) {
     return pay ? numberWithCommas(pay) : '     -';
    },
    itemReduce(item,type) {
      let reduceField = [];
      switch (type) {
        case 'sales':
          reduceField = ['netAmt','notaxAmt'];
          break;
        case 'dues':
          reduceField = ['specialTax','vilTax','eduTax','vatAmt','fund'];
          break;
      }
      let total = 0;
      for(const property in item) {
        if(reduceField.includes(property)) {
          total += item[property];
        }
      }
      return total;
    },
  //  grid methods
    onQueryCellInfo(args) {
      const { column:{ field },data,cell } = args;
      if(field === 'divName' && !data.isGroupFirstIdx) {
        cell.innerText = '';
        cell.style.border = 'none';
      }
    }
  }
};
</script>
